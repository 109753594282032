<template>
  <div class="about">
    <div
      class="
        flex flex-col
        items-center
        justify-center
        mx-6
        mt-2
        md:mt-16
        mb-10
        text-secondary text-white
      "
    >
      <h1
        class="
          w-auto
          text-xl
          md:text-5xl
          mb-12
          font-bold
          leading-loose
          tracking-wide
        "
      >
        Where is my Farm?
      </h1>

      <div
        class="
          flex flex-col
          w-12/12
          mb-8
          items-center
          leading-normal
          text-white text-2xl
          font-light
          space-y-6
        "
      >
        <div>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/D4c6W5TWCf0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div class>
          We could bootstrap a Farm in the coming days if there is demand for
          the game.
        </div>

        <div class="hidden">
          <div class="flex flex-col space-y-2 mb-10">
            <div>
              Accumulated MUSE points:
              <span class="font-bold">{{ musePoints }}</span>
            </div>
            <div>
              Your MUSE stake:
              <span class="font-bold">
                <!-- <TokenBalance
                  :erc20="'0'"
                  :account="account"
                  symbol="$MUSE"
                  decimals="18"
                /> -->
                {{ museStaked / 10 ** 18 }}
              </span>
            </div>

            <button
              class="
                bg-blue-700
                hover:bg-yellow-400
                rounded-2xl
                flex
                items-center
                justify-center
                w-full
                h-16
                text-white
                hover:text-black
                shadow-lg
              "
              @click="stakeMuse()"
            >
              <div class="px-2 text-2xl font-light inline-block">
                Stake MUSE
              </div>
            </button>
          </div>
          <div class="flex flex-col space-y-2">
            <div>
              Accumulated ROYAL points:
              <span class="font-bold">{{ royalPoints }}</span>
            </div>
            <div>
              Your ROYAL stake:
              <span class="font-bold">
                <!-- <TokenBalance
                  :erc20="royalStaked"
                  :account="account"
                  symbol="$ROYAL"
                  decimals="18"
                /> -->
                {{ royalStaked / 10 ** 18 }}
              </span>
            </div>

            <button
              class="
                bg-blue-700
                hover:bg-yellow-400
                rounded-2xl
                flex
                items-center
                justify-center
                w-full
                h-16
                text-white
                hover:text-black
                shadow-lg
              "
              @click="stakeRoyal()"
            >
              <div class="px-2 text-2xl font-light inline-block">
                Stake <span class="font-bold">ROYAL</span>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-4 w-1/3 hidden">
        <div class="pt-4">
          <button
            @click="unstake"
            class="
              bg-white
              hover:bg-yellow-400
              rounded-2xl
              flex
              items-center
              justify-center
              w-full
              h-24
              text-black
              hover:text-black
              shadow-lg
              border-4 border-black
            "
          >
            <div class="px-2 text-2xl inline-block">Unstake all</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TokenBalance from "@/components/TokenBalance.vue";

export default {
  components: { TokenBalance },
  data: function () {
    return {
      showModal: true,
      userMuseBalance: 0,
      userRoyalBalance: 0,
      museStaked: "0",
      royalStaked: "0",
      musePoints: 0,
      royalPoints: 0,
      hasTokenApproval: false,
    };
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init: async function () {
      if (this.$store.state.web3 == null) {
        return;
      }

      this.museStaked = (
        await this.$store.state.Farm.methods
          .museStaked(this.$store.state.account)
          .call()
      ).toString();
      this.royalStaked = (
        await this.$store.state.Farm.methods
          .royalStaked(this.$store.state.account)
          .call()
      ).toString();

      // console.log("royal staked: ", this.royalStaked);

      // alert(this.royalStaked);

      let points = await this.$store.state.Farm.methods
        .getPoints(this.$store.state.account)
        .call();

      console.log(points);
      this.musePoints = points.musepoints;
      this.royalPoints = points.royalpoints;
    },
    stakeMuse: async function () {
      if (!this.hasTokenApproval) {
        await this.approve(true);
      }
      const userMuseBalance = await this.$store.state.MuseTokenContract.methods
        .balanceOf(this.$store.state.account)
        .call();

      this.userMuseBalance = userMuseBalance;
      let minetx = await this.$store.state.Farm.methods
        .stakeMuse(this.userMuseBalance)
        .send({
          from: this.$store.state.account,
        });
    },
    stakeRoyal: async function () {
      console.log("thadsad ", this.hasTokenApproval);
      if (!this.hasTokenApproval) {
        await this.approve(false);
      }
      const userRoyalBalance =
        await this.$store.state.RoyalTokenContract.methods
          .balanceOf(this.$store.state.account)
          .call();

      this.userRoyalBalance = userRoyalBalance;
      let minetx = await this.$store.state.Farm.methods
        .stakeRoyal(this.userRoyalBalance)
        .send({
          from: this.$store.state.account,
        });
    },
    unstake: async function () {
      let minetx = await this.$store.state.Farm.methods.unstake().send({
        from: this.$store.state.account,
      });
    },
    approve: async function (isMuse) {
      let max =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      let remove = "0";

      if (isMuse) {
        const museAllowance = await this.$store.state.MuseTokenContract.methods
          .allowance(
            this.$store.state.account,
            this.$store.state.contracts.Farm
          )
          .call();

        if (parseInt(museAllowance) == 0) {
          await this.$store.state.MuseTokenContract.methods
            .approve(this.$store.state.contracts.Farm, max)
            .send({
              from: this.$store.state.account,
            });
        }
      } else {
        const royalAllowance =
          await this.$store.state.RoyalTokenContract.methods
            .allowance(
              this.$store.state.account,
              this.$store.state.contracts.Farm
            )
            .call();

        if (parseInt(royalAllowance) == 0) {
          await this.$store.state.RoyalTokenContract.methods
            .approve(this.$store.state.contracts.Farm, max)
            .send({
              from: this.$store.state.account,
            });
        }
      }
    },
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },

    account: function () {
      return this.$store.state.account;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap");
</style>
