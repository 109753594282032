<template>
  <div class="text-white mt-8 text-sm antialiased font-light">
    <GameHeader />
    <div class="flex justify-start mt-8">
      <div class="w-1/2">
        <div
          id="accessories"
          class="
            mt-4
            flex flex-col
            space-y-8
            roudned
            py-2
            rounded
            overflow-y-scroll
          "
        >
          <div>
            <h1 class="font-bold text-2xl mb-4">Attack Powerups</h1>
            <div class="flex space-x-2">
              <div
                v-for="(attack, i) in attacks"
                :key="i"
                class="
                  flex flex-col
                  justify-between
                  items-center
                  hover:border
                  cursor-pointer
                  upgrade
                  space-y-4
                "
                @click="buyAttack(attack.id, attack.priceEth)"
              >
                <!-- <div class="text-2xl">{{ attack.name }}</div> -->
                <img class="h-16" :src="attack.img" alt="" />

                <div class="flex flex-col items-center">
                  <!-- <div class="font-light">+10 APES</div> -->
                  <button class="focus:outline-none w-full px-6 leading-none">
                    +{{ attack.power }} Attack Power
                    <div>
                      <span
                        class="font-light text-md"
                        v-if="attack.priceEth > 0"
                      >
                        {{ attack.priceEth }} $ETH
                      </span>
                      <span v-if="attack.priceToken > 0 && attack.priceEth > 0"
                        >+</span
                      >
                      <span
                        class="font-light text-md"
                        v-if="attack.priceToken > 0"
                      >
                        {{ attack.priceToken }} $GOO
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1 class="font-bold text-2xl mb-4">Defense Powerups</h1>
            <div class="flex space-x-2">
              <div
                v-for="(defense, i) in defenses"
                :key="i"
                class="
                  flex flex-col
                  justify-between
                  items-center
                  hover:border
                  cursor-pointer
                  upgrade
                  space-y-4
                "
                @click="buyDefense(defense.id, defense.priceEth)"
              >
                <img class="h-16" :src="defense.img" alt="" />
                <!-- <div class="text-2xl">{{ defense.name }}</div> -->

                <div class="flex flex-col items-center">
                  <!-- <div class="font-light">+10 APES</div> -->
                  <button class="focus:outline-none w-full px-6 leading-none">
                    +{{ defense.power }} Defense Power
                    <div>
                      <span
                        class="font-light text-md"
                        v-if="defense.priceEth > 0"
                      >
                        {{ defense.priceEth }} $ETH
                      </span>
                      <span
                        v-if="defense.priceToken > 0 && defense.priceEth > 0"
                        >+</span
                      >
                      <span
                        class="font-light text-md"
                        v-if="defense.priceToken > 0"
                      >
                        {{ defense.priceToken }} $GOO
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-1/2 ml-32">
        <div class="flex w-full mt-10">
          <!-- <img src="@/assets/img/characters/spaceship2.gif" alt="" /> -->
          <div>
            <div>{{ totalUsers }} total users</div>
            <div
              v-for="(user, i) in users"
              :key="i"
              class="border p-4 mb-2 flex space-x-4"
            >
              <div class="text-center">
                <a
                  :href="`https://arbiscan.io/address/${user.address}`"
                  target="_blank"
                >
                  {{ user.address.substring(0, 8) }}..</a
                >
              </div>
              <div>defense: {{ user.defensePower }}</div>
              <div>attack: {{ user.attackPower }}</div>
              <div>score: {{ user.score }}</div>
              <div>Mines: {{ user.tokensPerBlock }} p/b</div>

              <div
                class="
                  cursor-pointer
                  bg-blue-100
                  text-gray-900
                  px-4
                  border
                  text-center
                "
                v-if="
                  attackPower > user.defensePower &&
                  user.address != $store.state.account
                "
                @click="attack(user.address)"
              >
                Attack
              </div>
            </div>
          </div>
          <img
            class="m-auto opacity-75 hidden"
            src="https://i.pinimg.com/originals/5d/85/d3/5d85d384b2438e68ca1872cbebb43fec.gif"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameHeader from "@/components/GameHeader.vue";

export default {
  components: { GameHeader },
  data: function () {
    return {
      users: [],
      totalUsers: 0,
      tokens: 10000,
      totalPot: 500000,
      qty: [],
      defensePower: 0,
      attackPower: 0,
      score: 0,
      tokensPerBlock: 0,
      attacks: [
        {
          id: 1,
          priceEth: "0",
          priceToken: "100",
          emission: 1,
          power: 100,
          name: "Sword",
          img: "http://pixelartmaker-data-78746291193.nyc3.digitaloceanspaces.com/image/8fd9223acb89db4.png",
        },
        {
          id: 2,
          priceEth: "0",
          priceToken: "15",
          emission: 1,
          name: "CumRocket",

          power: 10,

          img: "http://pixelartmaker-data-78746291193.nyc3.digitaloceanspaces.com/image/8fd9223acb89db4.png",
        },
      ],
      defenses: [
        {
          id: 1,
          priceEth: "0",
          priceToken: "100",
          emission: 1,
          name: "Divine Robe",
          power: 100,

          img: "http://pixelartmaker-data-78746291193.nyc3.digitaloceanspaces.com/image/8fd9223acb89db4.png",
        },
        {
          id: 2,
          priceEth: "0",
          priceToken: "15",
          name: "Litecoin Shield",
          emission: 1,
          power: 10,

          img: "http://pixelartmaker-data-78746291193.nyc3.digitaloceanspaces.com/image/8fd9223acb89db4.png",
        },
      ],
    };
  },
  methods: {
    toWei: function (num) {
      return this.$store.state.web3.utils.toWei(num);
    },
    updateTokens: function () {
      setInterval(() => {
        this.tokens += 10;
      }, 1000);
    },
    buyAttack: async function (id, priceEth) {
      let minetx = await this.$store.state.cobie.methods.buyAttack(id).send({
        value: priceEth > 0 ? this.toWei(priceEth) : 0,
        from: this.$store.state.account,
      });

      // location.reload();
    },
    attack: async function (user) {
      let minetx = await this.$store.state.cobie.methods.attack(user).send({
        from: this.$store.state.account,
      });
      // location.reload();
    },
    buyDefense: async function (id, priceEth) {
      let minetx = await this.$store.state.cobie.methods.buyDefense(id).send({
        value: priceEth > 0 ? this.toWei(upgrade.priceEth) : 0,
        from: this.$store.state.account,
      });
      // location.reload();
    },
    getMax: async function (id) {
      const accessoriesLeftToUse = await this.$store.state.cobie.methods
        .accessoriesLeftToUse(this.$store.state.account, id)
        .call();

      this.qty[id] = parseInt(accessoriesLeftToUse);
    },
    init: async function () {
      if (this.isConnected) {
        let attackPower = await this.$store.state.cobie.methods
          .attackPower(this.$store.state.account)
          .call();

        this.attackPower = parseInt(attackPower);

        let defensePower = await this.$store.state.cobie.methods
          .defensePower(this.$store.state.account)
          .call();

        this.defensePower = defensePower.toString();

        let score = await this.$store.state.cobie.methods
          .score(this.$store.state.account)
          .call();

        this.score = score.toString();

        let tokensPerBlock = await this.$store.state.cobie.methods
          .tokensPerBlock(this.$store.state.account)
          .call();

        this.tokensPerBlock = tokensPerBlock.toString() / 10 ** 18;

        let totalUsers = await this.$store.state.cobie.methods
          .totalUsers()
          .call();

        this.totalUsers = totalUsers;

        for (let i = 0; i < parseInt(totalUsers); i++) {
          let user = await this.$store.state.cobie.methods
            .userAtIndex(i)
            .call();

          let info = await this.$store.state.cobie.methods
            .getPlayerInfo(user)
            .call();

          let tokensPerBlock2 = await this.$store.state.cobie.methods
            .tokensPerBlock(user)
            .call();

          const userObj = {
            tokensPerBlock: tokensPerBlock2.toString() / 10 ** 18,
            address: user,
            attackPower: info._attackPower,
            defensePower: info._defensePower,
            lastAttackBlock: info._lastAttackBlock,
            score: info._score,
          };

          this.users.push(userObj);
        }
      }
    },
  },
  mounted: function () {
    // alert(this.upgrades[0].img);
    this.updateTokens();

    if (this.isConnected) {
      this.init();
    }
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
  },
  watch: {
    isConnected: async function () {
      this.init();
    },
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

/* #accessories {
  height: calc(100vh - 180px);
} */
.upgrade:hover {
  border: 1px white solid;
}
</style>
