<template>
  <div class="about">
    <div
      class="
        flex flex-col
        items-center
        justify-center
        mx-6
        mt-8
        md:mt-16
        mb-10
        text-secondary text-white
      "
    >
      <h1
        class="
          w-auto
          text-xl
          md:text-5xl
          mb-12
          font-bold
          leading-loose
          tracking-wide
        "
      >
        <!-- <img
          class="transform scale-75"
          src="../assets/img/tittle.svg"
          alt="Battle NFTs for ETH and TOKEN."
        /> -->
        Space GOO
      </h1>

      <div
        v-if="$store.state.correctNetwork == null"
        class="
          text-6xl text-red-600
          font-light
          text-left
          md:text-center
          w-full
          leading-tight
          mt-10
          mb-10
          animate-pulse
        "
      >
        Please switch your wallet to the Arbitrum Network and reload the page.
      </div>

      <div
        class="
          flex flex-col
          md:flex-row
          w-12/12
          mb-8
          justify-between
          items-center
          md:items-end
          gap-8
          leading-normal
          text-white text-2xl
          font-light
          transform
          scale-90
        "
      >
        <div
          class="
            topic
            flex flex-col
            justify-start
            items-center
            md:w-1/3
            text-center
            p-0
          "
        >
          <img class="h-20" src="../assets/img/register.svg" />
          <p class="w-9/12 mx-auto pt-12">
            Join the game
            <router-link class="" to="/ape">here</router-link>
          </p>
        </div>
        <div
          class="
            topic
            flex flex-col
            justify-start
            items-center
            md:w-1/3
            text-center
            p-0
          "
        >
          <img class="h-20" src="../assets/img/coins.svg" />
          <p class="w-9/12 mx-auto pt-8">
            You get GOO (erc20) every block based on what accessories you own.
          </p>
        </div>
        <div
          class="
            topic
            flex flex-col
            justify-start
            items-center
            md:w-1/3
            text-center
            p-0
          "
        >
          <img class="h-24 -m-4" src="../assets/img/shield.svg" />
          <p class="w-9/12 mx-auto pt-6">
            Increase your defense & attack powers to attack other players and
            win the ETH pot.
          </p>
        </div>
      </div>

      <div
        class="
          flex flex-col
          md:flex-row
          items-center
          md:items-start
          gap-8
          transform
          scale-90
        "
      >
        <div
          class="
            flex flex-col
            md:flex-row
            w-10/12
            items-center
            text-lg
            md:text-2xl
            leading-normal
            tracking-wide
            rounded-xl
            bg-blue-700 bg-opacity-25
            p-10
            border border-blue-200
            shadow-md
          "
        >
          <img class="w-20 h-20" src="../assets/img/alert.png" />
          <p
            class="
              text-white
              font-light
              pl-0
              md:pl-8
              mb-0
              pt-8
              md:pt-0
              text-center
              md:text-left
            "
          >
            Careful though, if you get attacked you lose 2% of your block $GOO
            production.
          </p>
        </div>
        <div
          class="
            flex flex-col
            md:flex-row
            w-10/12
            items-center
            text-lg
            md:text-2xl
            leading-normal
            tracking-wide
            rounded-xl
            bg-blue-700 bg-opacity-25
            p-10
            border border-blue-200
            shadow-md
          "
        >
          <img class="w-20 h-20 px-2" src="../assets/img/skull.png" />
          <p
            class="
              text-white
              font-light
              pl-0
              md:pl-8
              mb-0
              pt-8
              md:pt-0
              text-center
              md:text-left
            "
          >
            Raise your attack power for a higher chance to win the pot.
          </p>
        </div>
      </div>
      <div class="flex flex-col mt-16 w-7/12 md:w-3/12">
        <div class="px-0">
          <button
            class="
              bg-blue-700
              hover:bg-yellow-400
              rounded-2xl
              flex
              items-center
              justify-center
              w-full
              h-16
              text-white
              hover:text-black
              shadow-lg
            "
          >
            <router-link class="px-2 text-2xl font-light inline-block" to="/faq"
              >How To Play?</router-link
            >
          </button>
        </div>
        <div class="pt-4">
          <button
            class="
              bg-white
              hover:bg-yellow-400
              rounded-2xl
              flex
              items-center
              justify-center
              w-full
              h-16
              text-black
              hover:text-black
              shadow-lg
              border-2 border-black
            "
          >
            <router-link class="px-2 text-2xl inline-block" to="/ape"
              >Start playing</router-link
            >
          </button>
        </div>
      </div>
    </div>
    <Modal
      v-if="showModal"
      @close="showModal = false"
      :text="`This game is not an investment vehicle, it was made for fun. The contracts are not audited and there may be bugs. Use with caution.`"
      :title="`GOO is not an investment vehicle.`"
      :button="`I understand & agree.`"
    />
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      showModal: true,
    };
  },
  mounted: function () {},
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap");
</style>
