import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/About.vue";
// import Leaderboard from "../views/Leaderboard.vue";
// import Leaderboard2 from "../views/Leaderboard2.vue";
import Faqs from "../views/Faqs.vue";
import Ape from "../views/Ape.vue";
import Attacks from "../views/Attacks.vue";

import Farm from "../views/Farm.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/ape",
    name: "Ape",
    component: Ape,
  },
  {
    path: "/attacks",
    name: "Attacks",
    component: Attacks,
  },
  {
    path: "/",

    name: "About",
    component: About,
  },
  {
    path: "/faq",
    name: "Faqs",
    component: Faqs,
  },

  {
    path: "/farm",
    name: "Farm",
    component: Farm,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
