<template>
  <span class="address"><a target="_blank" :href="link" class="underline hover:text-primary">{{ display }}</a></span>
</template>

<script>
export default {
  name: "Address",
  components: {},
  props: {
    address: String
  },
  data: function() {
    return {};
  },
  methods: {},
  beforeMount: function() {},
  watch: {},
  computed: {
    display: function() {
      if (this.address == null) {
        return "";
      }
      return this.address.substring(0, 8) + "...";
    },
    link: function() {
      if (this.address == null) {
        return "";
      }
      return "https://etherscan.io/address/" + this.address;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
