import Vue from "vue";

import Vuex from "vuex";

import Contracts from "@/assets/contracts";
import ERC721ABI from "@/assets/ABIS/ERC721";
import ERC1155ABI from "@/assets/ABIS/ERC1155";
import ERC20ABI from "@/assets/ABIS/ERC20";
import NFTRaceAbi from "@/assets/ABIS/NFTRace";
import NFTRaceMuseAbi from "@/assets/ABIS/NFTRaceMuse";
import AirdropABI from "@/assets/ABIS/Airdrop";
import cobieABI from "@/assets/ABIS/CobieGoo";

import vNFTABI from "@/assets/ABIS/vNFT";
import casABI from "@/assets/ABIS/cas";

import GameAbi from "@/assets/ABIS/Game";
import FarmAbi from "@/assets/ABIS/Farm";
import AirdropAbi from "@/assets/ABIS/Airdrop";

import Web3 from "web3";

Vue.use(Vuex);

window.Vue = Vue;

export default new Vuex.Store({
  state: {
    web3: null,
    vNFTContract: null,
    AirdropperContract: null,
    MuseTokenContract: null,
    LotteryContract: null,
    contracts: {},
    account: null,
    correctNetwork: null,

    prize: 0,
  },
  mutations: {
    setWeb3: async function(state, web3, vueInstance) {
      state.contracts = Contracts;
      state.vNFTContract = new web3.eth.Contract(vNFTABI, Contracts.vNFT);
      state.GameContract = new web3.eth.Contract(GameAbi, Contracts.Game);
      state.AirdropContract = new web3.eth.Contract(
        AirdropAbi,
        "0x2b860c491E0141806DF58FeAa65fef67a46bf2Dd"
      );

      let chainId = await web3.eth.getChainId();
        console.log("CHAIIIN ID", chainId)
      if (chainId == 42161) {
        state.correctNetwork = "arbitrum";
      } else {
        state.correctNetwork = "WRONG";
      }

      state.MuseTokenContract = new web3.eth.Contract(
        ERC20ABI,
        Contracts.MuseToken
      );
      /*
          27946
          0x63b8398f3ebcf782015a0019a4300bc20e74cf94e6626e4b18f93dd85d150f34
          */
      state.AirdropperContract = new web3.eth.Contract(
        AirdropABI,
        "0x95470f8e330d20788e76e8da3063c9a19555ac0c" //TODO TO change when deploying
      );

      state.RoyalTokenContract = new web3.eth.Contract(
        ERC20ABI,
        Contracts.RoyalToken
      );

      state.cas = new web3.eth.Contract(
        casABI,
        "0x95470f8e330d20788e76e8da3063c9a19555ac0c"
      );

      state.cobie = new web3.eth.Contract(cobieABI, Contracts.CobieGoo);

      state.Farm = new web3.eth.Contract(FarmAbi, Contracts.Farm);

      state.ERC721 = async (contract) => {
        return new web3.eth.Contract(ERC721ABI, contract);
      };

      state.ERC1155 = async (contract) => {
        return new web3.eth.Contract(ERC1155ABI, contract);
      };

      state.NFTRace = new web3.eth.Contract(NFTRaceAbi, Contracts.NFTRace);
      state.NFTRaceMuse = new web3.eth.Contract(
        NFTRaceMuseAbi,
        Contracts.NFTRaceMuse
      );

      state.account = (await web3.eth.getAccounts())[0];
      state.web3 = web3;

      let prize = await state.web3.eth.getBalance(
        "0x1cb70925264f102bebcad42d01c34042f8269c9f"
      );
      // balance = this.$store.state.web3.toDecimal(balance);

      state.prize = prize / 10 ** 18 / 2;

      state.inBattleId = null;

      console.log("set Web3");
    },

    setInBattleId(state, payload) {
      state.inBattleId = payload;
    },
  },
  actions: {
    connectWallet: async function(context, vue) {
      const provider = await vue.$web3Modal.connect();
      const web3 = new Web3(provider);
      context.commit("setWeb3", web3, vue);

      provider.on("accountsChanged", (accounts) => {
        context.dispatch("connectWallet", vue);
      });

      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        context.dispatch("connectWallet", vue);
      });

      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        context.dispatch("connectWallet", vue);
      });
    },
  },
  modules: {},
});
