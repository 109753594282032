<template>
  <div class="flex justify-between items-center text-lg">
    <div class="flex">
      <router-link
        to="/ape"
        class="cursor-pointer px-4 border"
        v-bind:class="{
          'hover:bg-blue-100 hover:text-gray-900': togglePage,
          'bg-blue-100 text-gray-900': !togglePage,
        }"
      >
        Mine
      </router-link>
      <router-link
        to="/attacks"
        class="cursor-pointer px-4 border"
        v-bind:class="{
          'hover:bg-blue-100 hover:text-gray-900': !togglePage,
          'bg-blue-100 text-gray-900': togglePage,
        }"
      >
        Attacks
      </router-link>
    </div>

    <div class="flex items-center space-x-6">
      <div>
        Your score: <span>{{ score }}</span>
      </div>
      <div>
        Attack Power: <span>{{ attackPower }}</span>
      </div>
      <div>
        Defense Power: <span>{{ defensePower }}</span>
      </div>

      <!-- <div>Next Attack: <span>Now!</span></div> -->

      <div>
        Next Attack:
        <span class="text-red-600">
          <countdown :end-time="(lastTimeAttacked + 60 * 20) * 1000">
            <template v-slot:process="attack">
              <span>
                {{
                  `${attack.timeObj.h}:${attack.timeObj.m}:${attack.timeObj.s}`
                }}
              </span>
            </template>
            <template v-slot:finish>
              <span class="text-green-600 animate-pulse">Now!</span>
            </template>
          </countdown>
        </span>
      </div>

      <button
        class="
          border border-rounded
          px-6
          hover:bg-blue-100
          hover:text-black
          animate-pulse
        "
        @click="claimTokens"
      >
        You mine {{ tokensPerBlock }} $GOO per block!
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      lastTimeAttacked: 0,
      togglePage: false,
      qty: [],
      defensePower: 0,
      attackPower: 0,
      score: 0,
      tokensPerBlock: 0,
      dater: null,
      currentRouteName: "",
    };
  },
  methods: {
    claimTokens: async function () {
      let minetx = await this.$store.state.cobie.methods.claimTokens().send({
        from: this.$store.state.account,
      });
    },
    toWei: function (num) {
      return this.$store.state.web3.utils.toWei(num);
    },

    init: async function () {
      if (this.isConnected) {
        let attackPower = await this.$store.state.cobie.methods
          .attackPower(this.$store.state.account)
          .call();

        this.attackPower = attackPower.toString();

        let defensePower = await this.$store.state.cobie.methods
          .defensePower(this.$store.state.account)
          .call();

        this.defensePower = defensePower.toString();

        let score = await this.$store.state.cobie.methods
          .score(this.$store.state.account)
          .call();

        this.score = score.toString();

        let tokensPerBlock = await this.$store.state.cobie.methods
          .tokensPerBlock(this.$store.state.account)
          .call();

        this.tokensPerBlock = tokensPerBlock.toString() / 10 ** 18;

        let totalUsers = await this.$store.state.cobie.methods
          .totalUsers()
          .call();

        let lastAttackPerPlayer = await this.$store.state.cobie.methods
          .lastATtackPerPlayer(this.$store.state.account)
          .call();

        this.lastTimeAttacked = lastAttackPerPlayer;
      }
    },
  },
  mounted: function () {
    if (this.isConnected) {
      this.init();
    }

    this.currentRouteName = this.$route.name;
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
  },
  watch: {
    isConnected: async function () {
      this.init();
    },
    currentRouteName: async function (val) {
      if (val == "Attacks") this.togglePage = true;
      if (val == "Ape") this.togglePage = false;
    },
  },
};
</script>