<template>
  <div id="app" class="container m-auto text-gray-900">
    <notifications group="foo" position="top right" />

    <div
      class="
        flex
        md:flex-row
        flex-wrap flex-col
        justify-center
        md:justify-between
        items-center
        shadow-lg
        full-width
        px-10
        bg-gray-800 bg-opacity-100
        text-white
        font-light
      "
    >
      <div
        class="
          w-full
          md:w-auto
          flex
          justify-between
          md-:justify-start
          items-center
          text-xl
          md:text-4xl
          z-50
        "
      >
        <div class="py-4">
          <router-link to="/" class="flex items-center -mx-1">
            <img src="@/assets/img/logoGreen.svg" class="h-8 px-1" />
            <span class="text-xs px-1">v0.5 Beta</span>
          </router-link>
        </div>
        <div class="text-sm md:hidden" @click="toggleMenu">menu</div>
        <div class="hidden ml-6 md:flex -mx-3 items-center text-lg">
          <div class="px-0">
            <router-link
              to="/ape"
              class="rounded p-4 hover:bg-gray-700 hover:text-primary"
              >Play</router-link
            >
          </div>
          <!-- <div class="px-0">
            <router-link
              to="/leaderboard"
              class="rounded p-4 hover:bg-gray-700 hover:text-primary"
              >Attack NFTs</router-link
            >
          </div> -->
          <div class="px-0">
            <!-- <router-link
              to="/faq"
              class="rounded p-4 hover:bg-gray-700 hover:text-primary"
              >How To Play</router-link
            > -->

            <a href="https://pastebin.com/9cHugWAi" target="_blank"
              >How To Play</a
            >
          </div>

          <!-- <div class="px-3">
            <router-link to="/race">Race 🏎️</router-link>
          </div> -->

          <div class="px-0">
            <router-link
              to="/farm"
              class="rounded p-4 hover:bg-gray-700 hover:text-primary"
              >Farm</router-link
            >
          </div>

          <!-- <div class="px-3">
            <a href="http://docs.verynifty.io" target="_blank">Learn</a>
          </div> -->
        </div>
      </div>
      <div class="hidden md:flex flex -mx-3 items-center text-lg">
        <div class="flex mr-8">
          <img src="@/assets/img/chest.svg" class="h-8 px-1" />
          <span class="mr-1">Total Prize Pot:</span>
          <span class="">{{ $store.state.prize }} ETH</span>
        </div>
        <div class="px-3">
          <TokenBalance
            :erc20="CobieToken"
            :account="account"
            symbol="$GOO"
            decimals="18"
          />
        </div>
        <!-- <div class="px-3">
          <a href>
            <EthBalance :account="account" />
          </a>
        </div> -->

        <div class="px-3">
          <Address :address="account" />
        </div>
        <!-- <div class="toggle-market cursor-pointer px-3">
          <img src="assets/img/brush.svg" alt class="h-12" />
        </div>-->
      </div>
      <div
        class="w-full md:hidden"
        id="mobile-menu"
        v-if="menuActive"
        @click="toggleMenu"
      >
        <ul>
          <li class="mt-2"><Address :address="account" /></li>
          <li class="mt-2">
            <TokenBalance
              :erc20="CobieToken"
              :account="account"
              symbol="$GOO"
              decimals="18"
            />
          </li>
          <!-- <li class="mt-2">
            <EthBalance :account="account" />
          </li> -->

          <li class="mt-2">
            <router-link to="/leaderboard">Players</router-link>
          </li>
          <!-- <li class="mt-2"><router-link to="/race">Race 🏎️</router-link></li> -->

          <li class="mt-2">
            <a href="https://pastebin.com/9cHugWAi" target="_blank">FAQs</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="container m-auto text-gray-900"></div>

    <div
      v-if="correctNetwork == 'WRONG'"
      class="
        text-2xl text-white
        font-light
        text-left
        md:text-center
        w-full
        leading-tight
        mt-10
        mb-10
      "
    >
      Please switch your wallet to the Arbitrum Network and reload the page.
      <br />
      <button
        class="
          bg-white
          hover:bg-gray-100
          text-gray-800
          font-semibold
          py-2
          px-4
          border border-gray-400
          rounded
          shadow
          mt-10
        "
        @click="connectToArbitrum()"
      >
        Switch network using Arbitrum
      </button>
    </div>
    <div v-if="correctNetwork != 'WRONG'">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import TokenBalance from "@/components/TokenBalance.vue";
import EthBalance from "@/components/EthBalance.vue";
import Address from "@/components/Address.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: { TokenBalance, EthBalance, Address, Footer },
  data: function () {
    return {
      menuActive: false,
      prize: 0,
    };
  },
  props: {},
  beforeMount: function () {
    if (this.$web3Modal.providerController.cachedProvider != null) {
      console.log(
        "Connecting back to: ",
        this.$web3Modal.providerController.cachedProvider
      );
      this.$store.dispatch("connectWallet", this);
    }
  },

  mounted: async function () {
    await this.getPrize();

    //   if (this.$store.state.correctNetwork != "arbitrum") {
    //   alert("Connect to the Arbitrum network and refresh the site");
    // }
  },
  computed: {
    account: function () {
      return this.$store.state.account;
    },
    CobieToken: function () {
      return this.$store.state.contracts.CobieGoo;
    },
    correctNetwork: function () {
      return this.$store.state.correctNetwork;
    },
  },
  methods: {
    getPrize: async function () {
      // var balance = await this.$store.state.web3.eth.getBalance(
      //   "0x0dd929f8bec1bf4d5f39b9d18d93a7915b44e3f3"
      // );
      // this.prize = balance / 10 ** 18;
    },
    toggleMenu: function () {
      this.menuActive = !this.menuActive;
      // some code to filter users
    },
    connectToArbitrum: async function () {
      await ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xA4B1",
            chainName: "Arbitrum One",
            nativeCurrency: {
              name: "AETH",
              symbol: "AETH",
              decimals: 18,
            },
            rpcUrls: ["https://arb1.arbitrum.io/rpc"],
            blockExplorerUrls: ["https://arbiscan.io"],
          },
        ],
      });
      location.reload();
    },
  },
};
</script>

<style></style>
