<template>
  <div class="text-white mt-8 text-sm antialiased font-light">
    <GameHeader />
    <div class="flex justify-start mt-8">
      <div class="w-1/2">
        <div
          id="accessories"
          class="
            mt-4
            flex flex-col
            space-y-8
            roudned
            py-2
            rounded
            overflow-y-scroll
          "
        >
          <!-- Start row menu -->
          <div
            class="flex items-end space-x-16"
            v-for="(obj, i) in accessories"
            :key="i"
          >
            <div class="flex items-end -mx-4">
              <div class="items-center flex flex-col justify-between">
                <img class="h-24 px-4" :src="obj.img" alt="" />
                <div class="text-center text-lg leading-none mt-2">
                  {{ obj.name }}
                </div>
              </div>

              <div class="flex flex-col items-center px-4 space-y-2">
                <div class="flex space-x-10">
                  <div class="flex flex-col mb-4">
                    <div class="font-light text-lg">
                      +{{ obj.emission }} $GOO P/B
                    </div>
                    <div>
                      <input
                        placeholder="0"
                        v-model="qty[obj.id]"
                        type="number"
                        min="0"
                        class="
                          pl-2
                          w-1/2
                          bg-transparent
                          focus:outline-none
                          border-blue-100 border
                        "
                      />

                      <button
                        @click="getMax(obj.id)"
                        class="
                          w-1/2
                          focus:outline-none
                          border border-rounded
                          hover:bg-blue-100
                          hover:text-black
                        "
                      >
                        MAX
                      </button>
                    </div>

                    <button
                      class="
                        mt-4
                        focus:outline-none
                        w-full
                        border border-rounded
                        px-6
                        hover:bg-blue-100
                        hover:text-black
                      "
                      @click="buyAccessory(obj.id, obj.priceEth * qty[obj.id])"
                      v-bind:class="{ 'animate-pulse': qty[obj.id] > 0 }"
                    >
                      <span class="font-light text-md" v-if="obj.priceEth > 0">
                        {{ obj.priceEth * qty[obj.id] }} $ETH
                      </span>
                      <span v-if="obj.priceToken > 0 && obj.priceEth > 0"
                        >+</span
                      >
                      <span
                        class="font-light text-md"
                        v-if="obj.priceToken > 0"
                      >
                        {{ obj.priceToken * qty[obj.id] }} $GOO
                      </span>
                    </button>
                  </div>

                  <div>
                    <div class="flex space-x-2">
                      <div
                        v-for="(upgr, i) in upgrades[obj.id - 1]"
                        :key="i"
                        class="
                          flex flex-col
                          justify-between
                          items-center
                          hover:border
                          cursor-pointer
                          upgrade
                          space-y-4
                        "
                        @click="buyUpgrade(upgr)"
                      >
                        <img class="h-16" :src="upgr.img" alt="" />

                        <div class="flex flex-col items-center">
                          <!-- <div class="font-light">+10 APES</div> -->
                          <button
                            class="focus:outline-none w-full px-6 leading-none"
                          >
                            +{{ upgr.extraQtyAllowed }} {{ obj.name }}
                            <div>
                              <span
                                class="font-light text-md"
                                v-if="upgr.priceEth > 0"
                              >
                                {{ upgr.priceEth }} $ETH
                              </span>
                              <span
                                v-if="upgr.priceToken > 0 && upgr.priceEth > 0"
                                >+</span
                              >
                              <span
                                class="font-light text-md"
                                v-if="upgr.priceToken > 0"
                              >
                                {{ upgr.priceToken }} $GOO
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End row add buys and attack menus -->
        </div>
      </div>

      <div class="w-1/2">
        <div class="flex w-full mt-10">
          <!-- <img src="@/assets/img/characters/spaceship2.gif" alt="" /> -->
          <img
            class="m-auto"
            src="https://i.pinimg.com/originals/5d/85/d3/5d85d384b2438e68ca1872cbebb43fec.gif"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameHeader from "@/components/GameHeader.vue";

export default {
  components: { GameHeader },
  data: function () {
    return {
      tokens: 10000,
      totalPot: 500000,
      qty: [],
      defensePower: 0,
      attackPower: 0,
      score: 0,
      tokensPerBlock: 0,
      accessories: [
        {
          id: 1,
          priceEth: 0.00029,
          priceToken: 0,
          emission: "0.01",
          name: "Quartz",
          img: "https://cdn-icons-png.flaticon.com/128/4887/4887564.png",
        },
        // {
        //   id: 2,
        //   priceEth: 0.00029,
        //   priceToken: 0,
        //   emission: 1,
        // },
        // {
        //   id: 3,
        //   priceEth: 0.00029,
        //   priceToken: 0,
        //   emission: 1,
        // },
        // {
        //   id: 4,
        //   priceEth: 0.00029,
        //   priceToken: 0,
        //   emission: 1,
        // },
        // {
        //   id: 5,
        //   priceEth: 0.00029,
        //   priceToken: 0,
        //   emission: 1,
        // },
      ],
      upgrades: [
        // Upgrades for accessory 1
        [
          {
            id: 1,
            accessoryId: 1,
            img: "https://cdn-icons-png.flaticon.com/512/826/826917.png",
            priceEth: "0.00029",
            priceToken: 0,
            requiredAccessoriesOwned: 10,
            extraQtyAllowed: 20,
          },
          // {
          //   id: 2,
          //   accessoryId: 1,
          //   priceEth: "0",
          //   priceToken: 1,
          //   requiredAccessoriesOwned: 30,
          //   extraQtyAllowed: 10,
          //   img: "http://pixelartmaker-data-78746291193.nyc3.digitaloceanspaces.com/image/8fd9223acb89db4.png",
          // },
          // {
          //   id: 3,
          //   accessoryId: 1,
          //   priceEth: "0",
          //   priceToken: 5,
          //   requiredAccessoriesOwned: 40,
          //   extraQtyAllowed: 5,
          //   img: "http://pixelartmaker-data-78746291193.nyc3.digitaloceanspaces.com/image/8fd9223acb89db4.png",
          // },
        ],

        //Upgrades for accessory 2
      ],
    };
  },
  methods: {
    toWei: function (num) {
      return this.$store.state.web3.utils.toWei(num);
    },
    updateTokens: function () {
      setInterval(() => {
        this.tokens += 10;
      }, 1000);
    },
    buyAccessory: async function (id, priceEth) {
      //  uncomment this when ready
      let minetx = await this.$store.state.cobie.methods
        .buyAccessory(id, this.qty[id])
        .send({
          value: this.toWei(priceEth.toString()),
          from: this.$store.state.account,
        });

      location.reload();
    },
    buyUpgrade: async function (upgrade) {
      //  uncomment this when ready

      console.log();
      let minetx = await this.$store.state.cobie.methods
        .buyUpgrade(upgrade.id)
        .send({
          value: upgrade.priceEth > 0 ? this.toWei(upgrade.priceEth) : 0,
          from: this.$store.state.account,
        });
      location.reload();
    },
    getMax: async function (id) {
      const accessoriesLeftToUse = await this.$store.state.cobie.methods
        .accessoriesLeftToUse(this.$store.state.account, id)
        .call();
      Vue.set(this.qty, id, parseInt(accessoriesLeftToUse))
    },
    init: async function () {
      if (this.isConnected) {
        let attackPower = await this.$store.state.cobie.methods
          .attackPower(this.$store.state.account)
          .call();

        this.attackPower = attackPower.toString();

        let defensePower = await this.$store.state.cobie.methods
          .defensePower(this.$store.state.account)
          .call();

        this.defensePower = defensePower.toString();

        let score = await this.$store.state.cobie.methods
          .score(this.$store.state.account)
          .call();

        this.score = score.toString();

        let tokensPerBlock = await this.$store.state.cobie.methods
          .tokensPerBlock(this.$store.state.account)
          .call();

        this.tokensPerBlock = tokensPerBlock.toString() / 10 ** 18;
      }
    },
  },
  mounted: function () {
    // alert(this.upgrades[0].img);
    this.updateTokens();

    if (this.isConnected) {
      this.init();
    }
  },
  computed: {
    isConnected: function () {
      return this.$store.state.web3 != null;
    },
  },
  watch: {
    isConnected: async function () {
      this.init();
    },
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

/* #accessories {
  height: calc(100vh - 180px);
} */
.upgrade:hover {
  border: 1px white solid;
}
</style>
