<template>
  <div class="about">
    <div
      class="
        flex flex-col
        items-center
        justify-center
        mx-6
        mt-8
        md:mt-16
        mb-10
        text-secondary text-white
      "
    >
      <h1
        class="
          w-auto
          text-xl
          md:text-5xl
          mb-12
          font-bold
          leading-normal
          tracking-wide
        "
      >
        Frequently Asked Questions
      </h1>

      <div
        class="
          flex flex-col
          w-12/12
          mb-16
          leading-normal
          text-white text-2xl
          font-light
          space-y-8
          rounded-lg
          p-10
          bg-blue-700 bg-opacity-25
          border border-blue-200 border-opacity-100
          shadow-md
        "
      >
        <div class="flex flex-col space-y-0">
          <div class="font-bold">What is NFT Royal?</div>
          <div>
            NFT Royal is a fun game created by the
            <a class="underline" href="htps://verynifty.io" target="_blank"
              >VeryNifty</a
            >
            Team, the same team behind
            <a class="underline" href="https://nft20.io" target="_blank"
              >NFT20</a
            >,
            <a class="underline" href="https://wraplp.com" target="_blank"
              >wraplp.com</a
            >
            and
            <a
              class="underline"
              href="https://gallery.verynifty.io"
              target="_blank"
              >The VeryNifty Game.</a
            >
          </div>
        </div>
        <div class="flex flex-col space-y-0">
          <div class="font-bold">How does it work?</div>
          <div class="pb-4">
            You need to register your NFT as a player by burning 90 ROYAL
            tokens. Once you join the game you have a countdown of 3 days until
            your character dies.
          </div>
          <div>
            Other players can attack you and make you die faster (remove time
            from your coutndown), to get more hours of life you need to burn
            royal. The amount of hours you get per 1 ROYAL burned is decided by
            this curve
            <a
              href="https://www.desmos.com/calculator/g6wvwk85id"
              target="_blank"
              >desmos.com/calculator/g6wvwk85id</a
            >
          </div>
          <div>
            When no players remain, The player who lived the longest time will
            earn the ETH prize.
          </div>
        </div>
        <div class="flex flex-col space-y-0">
          <div class="font-bold">How much is the ETH prize?</div>
          <div>
            The ETH prize is decided by players. Every time you attack someone,
            you get more ROYAL to buy more TOD but you also need to spend some
            ETH (you choose depending on which atack). 80% of that ETH goes to
            the final prize, 10% goes to the team and 10% goes to buy back MUSE
            for the DAO/Holders.
          </div>
        </div>
        <div class="flex flex-col space-y-0">
          <div class="font-bold">
            How can I get more ROYAL tokens to buy more life?
          </div>
          <div>
            You get 6.9 royal per day (re calculated every second). Additionally
            you can attack other players and you get more tokens (as well as
            lower their Time Of Death) depending on the attack that you use.
          </div>
        </div>
        <div class="flex flex-col space-y-0">
          <div class="font-bold">Can I rug pull?</div>
          <div>
            YES, there is a rug pull function in the contract (only accessible
            from Etherscan), anyone that holds over 69% of the supply of Royal
            can execute that function and he will end the game and take the
            prize. Don't let anyone rug pull!.
          </div>
        </div>
        <div class="flex flex-col space-y-0">
          <div class="font-bold">What is the token distribution?</div>
          <div>
            Royal was given via airdrop, no tokens were sold or distributed for
            team members.
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row items-center md:items-start gap-8">
        <div
          class="
            flex
            w-10/12
            items-center
            text-lg
            md:text-2xl
            leading-normal
            tracking-wide
            rounded-xl
            bg-blue-700 bg-opacity-25
            p-10
            border border-blue-200
            shadow-md
          "
        >
          <img class="w-20 h-20" src="../assets/img/alert.png" />
          <p class="text-white font-light pl-8 mb-0 pt-0">
            Careful though, if you get attacked you lose 2% of your block $GOO
            production.
          </p>
        </div>
        <div
          class="
            flex
            w-10/12
            items-center
            text-lg
            md:text-2xl
            leading-normal
            tracking-wide
            rounded-xl
            bg-blue-700 bg-opacity-25
            p-10
            border border-blue-200
            shadow-md
          "
        >
          <img class="w-20 h-20 px-2" src="../assets/img/skull.png" />
          <p class="text-white font-light pl-8 mb-0 pt-0">
            Raise your attack power for a higher chance to win the pot.
          </p>
        </div>
      </div>
      <div class="flex flex-col mt-16 w-1/3">
        <div class="pt-4">
          <button
            class="
              bg-white
              hover:bg-yellow-400
              rounded-2xl
              flex
              items-center
              justify-center
              w-full
              h-24
              text-black
              hover:text-black
              shadow-lg
              border-4 border-black
            "
          >
            <router-link class="px-2 text-2xl inline-block" to="/ape"
              >Start playing</router-link
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      showModal: true,
    };
  },
  mounted: function () {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap");
</style>
