<template>
  <div class="w-full mt-64">
    <div class="bg-main antialiased tracking-normal leading-normal">
      <div
        class="
          flex
          justify-start
          full-width
          bg-dark bg-opacity-25
          text-white
          relative
          border-t-2 border-dashed border-opacity-0
        "
      >
        <div
          class="
            pt-16
            pb-8
            pr-16
            w-12/12
            mx-auto
            md:mx-0
            md:w-5/5
            items-center
            md:items-end
            flex flex-col-reverse
            md:flex-row
            text-center
            sm:text-left
            md:justify-between
          "
        >
          <div
            class="
              w-full
              md:w-1/3
              p-8
              rounded-xl
              border-none
              bg-black bg-opacity-25
              mt-8
              md:mt-0
              ml-0
              md:ml-8
            "
          >
            <div class="text-secondary font-bold text-3xl mb-2">THE GOO</div>

            <div class="mb-2 text-2xl pr-6 tracking-wide font-light">
              This game is an experimental game and was done in 1 day without
              testing. Any risks you take using this platform are your own. This
              is not an investment vehicle. Caution is advised.
            </div>
            <div class="">
              <!-- <a
                href="https://twitter.com/niftymuseum"
                target="_blank"
                class="text-xl text-white hover:text-blue-400"
                >Twitter</a
              > -->
            </div>
          </div>
          <div
            class="
              md:w-1/2
              md:flex
              p-8
              text-xl
              rounded-xl
              border-none
              bg-black bg-opacity-25
              font-light
            "
          >
            <div class="w-full md:border-l pl-4">
              <ul class="list-reset">
                <li>
                  <a
                    href="https://discord.gg/kfByfux"
                    target="_blank"
                    class="p-1 rounded hover:text-white hover:bg-black"
                    >Discord</a
                  >
                </li>

                <li>
                  <a
                    href="https://twitter.com/niftymuseum"
                    target="_blank"
                    class="p-1 rounded hover:text-white hover:bg-black"
                    >Twitter</a
                  >
                </li>
              </ul>
            </div>
            <div class="w-full border-none md:border-l pl-4 md:px-4">
              <ul class="list-reset">
                <li>
                  <a
                    href="https://arbiscan.com/address/0xB0Ccf5Ff2e5e67e253Bb0c061a822EaCF231e5dC"
                    class="p-1 rounded hover:text-white hover:bg-black"
                    target="_blank"
                    >Game Contract</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <img
            class="h-10 mb-8 md:mb-0"
            src="../assets/img/veryNifty_logo.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  props: {},
  data: function () {
    return {};
  },
  methods: {},
  beforeMount: function () {},
  watch: {},
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
